import Footer from '../../components/Footer';
import Header from '../../components/Header';

export default function MainLayout(props) {
  return (
      <>
        <Header/>
        {props.children}
        <Footer
            className={'text-black font-bold py-6 xl:pl-24 px-5 lg:text-left'}/>
      </>
  );
}
