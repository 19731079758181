import {ApiService} from './ApiService';

export const HomeService = {

  async convertPdfToDocx(file) {
    return await ApiService.init({
      headers: {'Content-Type': 'multipart/form-data'},
    }).post('/api/document/convert-pdf', file);
  },

  async downloadOriginalDocx(docId) {
    return await ApiService.init({responseType: 'arraybuffer'}).
        get('/api/document/download-original/' + docId);
  },

  async downloadReviewedDocx(docId) {
    return await ApiService.init({responseType: 'arraybuffer'}).
        get('/api/document/download-reviewed/' + docId);
  },

  async downloadPdf(docId) {
    return await ApiService.init({responseType: 'arraybuffer'}).
        get('/api/document/download-pdf/' + docId);
  },

  async uploadReviewedDocx(docId, file) {
    return await ApiService.init().post('/api/document/upload-reviewed', file, {
      params: {
        doc_id: docId,
      },
    });
  },

  async searchDoc(params) {
    return await ApiService.init().get('/api/document/search/', {
      params,
    });
  },
  async exportCsv(params) {
    return await ApiService.init().get('/api/document/export/', {
      params,
    });
  },
};
