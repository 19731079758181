import React, {useState} from 'react';
import {Form, Input, message} from 'antd';
import {useHistory} from 'react-router-dom';

import styles from './styles.module.scss';
import Footer from '../../components/Footer';
import {CONFIG} from '../../config';
import {objRoute} from '../../routes';
import {AuthService} from '../../services/AuthService';
import {util} from '../../helpers/util';

export default function SignUp() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);

  const redirectSignIn = () => {
    history.push(objRoute.signIn.path);
  };

  const onFinish = async (values) => {
    setIsDisabled(true);
    try {
      let dataForm = {...values};
      delete dataForm.confirmPassword;

      await AuthService.signUp(dataForm);
      setIsDisabled(false);
      history.push(objRoute.signIn.path);
      message.success(CONFIG.textSignUpSuccessfully);
    } catch (err) {
      setIsDisabled(false);
      if (err && err.response && err.response.data) {
        message.error(
            util.getTextByKey(err.response.data.detail, CONFIG.errorsCode));
      }
    }
  };

  return (
      <div className={styles.bgLogin + ' h-full min-h-screen'}>
        <div className='container-login md:w-6/12 w-10/12 mx-auto'>
          <div className={styles.title + ' text-white pt-24 text-center'}>
            OCR Tool Website
          </div>
          <div className={styles.description + ' mt-5 text-center'}>
            {'Sign Up'}
          </div>
          <Form
              className='mt-12 xl:w-6/12 w-10/12  mx-auto'
              form={form}
              onFinish={onFinish}
          >
            <div className={styles.itemForm + ' mx-auto'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                {'First name'}
              </div>
              <Form.Item
                  name="first_name"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={50}
                       type='text'/>
              </Form.Item>
            </div>

            <div className={styles.itemForm + ' mx-auto mt-4'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                {'Last name'}
              </div>
              <Form.Item
                  name="last_name"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={50}
                       type='text'/>
              </Form.Item>
            </div>

            <div className={styles.itemForm + ' mx-auto mt-4'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                E-mail
              </div>
              <Form.Item
                  name="email"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                    {
                      type: 'email',
                      message: CONFIG.messageValidForm.email,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={50}
                       type='text'/>
              </Form.Item>
            </div>

            <div className={styles.itemForm + ' mx-auto mt-4'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                Username
              </div>
              <Form.Item
                  name="username"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={50}
                       type='text'/>
              </Form.Item>
            </div>

            <div className={styles.itemForm + ' mx-auto mt-4'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                {'Password'}
              </div>
              <Form.Item
                  name="password"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                    {
                      pattern: CONFIG.regex.password,
                      message: CONFIG.messageValidForm.passwordInvalid,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={50}
                       type='password'/>
              </Form.Item>
            </div>

            <div className={styles.itemForm + ' mx-auto mt-4'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                {'Confirm Password'}
              </div>
              <Form.Item
                  name="confirmPassword"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                    ({getFieldValue}) => util.validateByDependency(
                        getFieldValue('password'),
                        CONFIG.messageValidForm.passwordMatch),
                  ]}
              >
                <Input className='w-full' maxLength={50}
                       type='password'/>
              </Form.Item>
            </div>


            <div className={'mt-12 xl:mt-16'}>
              <div className={'text-center'}>
                <button className="btn-submit-form block mx-auto mb-3"
                        disabled={isDisabled}>
                  {'Sign Up'}
                </button>
                <a onClick={redirectSignIn}
                   className={styles.textSignIn + ' text-white'}>
                  {'Go to sign in'}</a>
              </div>

            </div>
            <div className='mt-6 xl:mt-16 text-center pb-6'>
              <Footer/>
            </div>
          </Form>
        </div>
      </div>
  );
}
