import axios from 'axios';
import {CONFIG} from '../config';
import {objRoute} from '../routes';

export const ApiService = {
  init: (config) => {
    const token = localStorage.getItem(CONFIG.accessToken);

    const instanceAxios = axios.create({
      timeout: 120000,
      // baseURL: 'http://13.78.81.137:5100/',
      headers: {'Content-Type': 'application/json'},
      ...config,
    });

    instanceAxios.interceptors.request.use(function(config) {
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    }, function(error) {
      return Promise.reject(error);
    });

    instanceAxios.interceptors.response.use(function(response) {
      return response;
    }, function(error) {
      const status = error && error.response && error.response.status;
      if ((parseInt(status) === 401)) {
        localStorage.removeItem(CONFIG.accessToken);
        window.location.href = objRoute.signIn.path;
      }
      return Promise.reject(error);
    });
    return instanceAxios;
  },

};
