import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {DownOutlined} from '@ant-design/icons';

import Logo from '../../assets/images/logo_bimco.png';
import styles from './styles.module.scss';
import {Dropdown, Menu, message} from 'antd';
import {CONFIG} from '../../config';
import {objRoute} from '../../routes';
import {AuthService} from '../../services/AuthService';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const history = useHistory();

  const redirectPage = (path) => {
    history.push(path);
  };

  const signOut = () => {
    localStorage.removeItem(CONFIG.accessToken);
    localStorage.removeItem('userData');
    history.push(objRoute.signIn.path);
  };
  const getInformationUser = async () => {
    try {
      let result = await AuthService.getInformationUser();
      setDataUser(result.data || {});
    } catch (e) {
      console.error(e);
      message.error(CONFIG.error);
    }
  };
  const menu = (
      <Menu>
        <Menu.Item key="signOut">
          <a onClick={signOut}>
            {'Sign out'}
          </a>
        </Menu.Item>

      </Menu>
  );

  useEffect(() => {
    getInformationUser();
  }, []);

  return (
      <div className="flex flex-wrap py-2 shadow-lg">
        <div className="w-full">
          <nav
              className="relative flex flex-wrap items-center justify-between py-6 bg-blueGray-500 rounded">
            <div
                className="flex w-full flex-wrap items-center justify-between">
              <div
                  className="w-full lg:w-auto px-5 xl:px-0 xl:ml-24 lg:absolute flex justify-between lg:justify-start">
                <img onClick={() => redirectPage('/')} src={Logo}
                     className={styles.logo + ' cursor-pointer'}/>

                <button
                    className="border-1px-primary color-text-primary cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                    type="button"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                  ☰
                </button>
              </div>
              <div
                  className={
                    'lg:flex flex-grow items-center justify-end xl:mr-24' +
                    (menuOpen ? ' flex' : ' hidden')
                  }
                  id="example-navbar-info"
              >
                <ul
                    className="w-full lg:w-auto px-5 lg:px-0 flex mt-4 lg:mt-0 flex-col lg:flex-row list-none">

                  {dataUser && <li className="nav-item flex items-center ">
                    <Dropdown overlay={menu}>
                      <a
                          className="text-menu px-2 py-2 ant-dropdown-link text-black"
                          onClick={e => e.preventDefault()}>
                        {`${dataUser.first_name} ${dataUser.last_name}`}
                        <DownOutlined className={'ml-2'}/>
                      </a>
                    </Dropdown>
                  </li>}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
  );
}
