import styles from './styles.module.scss';

export default function Footer(props) {

  const className = props.className || '';
  const year = (new Date()).getFullYear();
  return (
      <footer className={styles.attribute + ' shadow-inner ' + className}>
        © 2017-{year} NTQ Solution JSC &nbsp;
        <span
            className={styles.textPrimary +
            ' cursor-pointer'}>{'Privacy'} </span>
        <span className={'m-2'}>·</span>
        <span
            className={styles.textPrimary + ' cursor-pointer'}>{'Terms'}</span>
      </footer>
  );
}
