import {Upload} from 'antd';
import styles from './styles.module.scss';

const {Dragger} = Upload;

export default function CustomUploadDragger(props) {
  let configUpload = props.configUpload || {};
  let buttonUploadName = props.buttonUploadName || 'attach image';
  const fileList = props.fileList || [];
  const config = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest({file, onSuccess}) {
      onSuccess('ok');
    },
    onChange(info) {
      props.getFileList && props.getFileList(info.fileList);
    },
    ...configUpload,
  };

  return (
      <Dragger
          fileList={fileList}
          {...config} className={props.className || ''}>
        <p className="ant-upload-drag-icon">
          <button className={styles.btnChooseFile +
          ' uppercase mb-0 mx-auto font-bold flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-color-primary md:py-2 md:text-lg md:px-10'}>{buttonUploadName}
          </button>
        </p>
        <p className={styles.descriptionUpload}>{
          'Click or drag file this area to upload'}</p>
      </Dragger>
  );
}
