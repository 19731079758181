export const CONFIG = {
  messageValidForm: {
    required: 'This field is required',
    email: 'This field is not a valid email',
    passwordMatch: 'The passwords that you entered do not match!',
    passwordInvalid: 'The password is required: minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character',

  },
  regex: {
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g,
  },
  error: `Something went wrong`,
  messageMustSelectFile: 'You must select file',
  textDescriptionFeature: 'Sentiment scores are provided on a 1 point scale. The closer the sentiment score is to 1, indicates positive sentiment. The closer it is to 0, indicates negative sentiment.',
  wrongCode: 'Wrong code',
  networkOrTimoutError: 'There has been a network or timeout error',
  userOrPasswordNotExist: 'The account or password does not exist',
  textUploadFileSuccessfully: 'Upload file successfully!',
  textSignUpSuccessfully: 'Sign up successfully!',
  textWrongAccountOrPassword: 'Wrong account or password!',
  textConvertPdfSuccessfully: 'Convert PDF to docx successfully!',
  errorsCode: [
    {
      key: 'DUPLICATE_USERNAME',
      text: 'Username is already existed',
    },
    {
      key: 'DUPLICATE_EMAIL',
      text: 'Email is already existed',
    },
  ],
  accessToken: 'access_token',
  pageSize: 10,
};