import {ApiService} from './ApiService';

export const AuthService = {

  async signIn(params) {
    return await ApiService.init({
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    }).post('/api/auth/login', params);
  },

  async signUp(params) {
    return await ApiService.init().put('/api/users/add', params);
  },

  async getInformationUser() {
    return await ApiService.init().get('/api/users/me');
  },

};
