import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.css';
import './assets/styles/global.scss';
import './assets/styles/override.scss';
import './index.css';

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);


