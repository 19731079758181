import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {Spin} from 'antd';
import {objRoute} from '../../routes';

const EmptyLayout = (props) => {
  return props.children;
};

function AppRoute(props) {
  const {component: Component, requiredLogin, ...rest} = props;
  const [checkedPermission, setCheckedPermission] = useState(false);
  const Layout = props.layout || EmptyLayout;

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    if (!requiredLogin) {
      setCheckedPermission(true);
    } else {
      accessToken
          ? setCheckedPermission(true)
          : window.location.href = objRoute.signIn.path;
    }
  }, []);

  if (!checkedPermission) {
    return (
        <div className="flex justify-center mt-16">
          <Spin/>
        </div>
    );
  }

  return (
      <Route
          {...rest}
          render={(props) =>
              <Layout>
                <Component {...props} />
              </Layout>
          }
      />
  );
}

export default AppRoute;
