import {Button, message, Upload} from 'antd';
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';

import {HomeService} from '../../services/HomeService';
import {CONFIG} from '../../config';
import {useState} from 'react';

export default function CustomSelectUpload(props) {
  let configUpload = props.configUpload || {};
  let id = props.id || null;
  let buttonUploadName = props.buttonUploadName || 'Click to Upload';

  const [isDownloadingUpload, setIsDownloadingUpload] = useState(
      false);

  const uploadFile = async (file) => {
    if (id) {
      try {
        setIsDownloadingUpload(true);
        let formData = new FormData();
        formData.append('file', file);
        await HomeService.uploadReviewedDocx(id, formData);
        setIsDownloadingUpload(false);
        props.callbackUpdateData && props.callbackUpdateData(true);
        message.success(CONFIG.textUploadFileSuccessfully);
      } catch (e) {
        setIsDownloadingUpload(false);
        console.error(e);
        message.error(e);
      }
    }
  };

  const config = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest({file, onSuccess}) {
      onSuccess('ok');
      uploadFile(file);
    },
    ...configUpload,
  };

  if (isDownloadingUpload) {
    return <LoadingOutlined/>;
  }

  return (
      <Upload {...config} className={'upload-list-inline upload-docx'}>
        <Button icon={<UploadOutlined/>}>{buttonUploadName}</Button>
      </Upload>
  );
}
