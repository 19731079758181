import React, {useState} from 'react';
import {Form, Input, message} from 'antd';
import {useHistory} from 'react-router-dom';

import styles from './styles.module.scss';
import Footer from '../../components/Footer';
import {CONFIG} from '../../config';
import {objRoute} from '../../routes';
import {AuthService} from '../../services/AuthService';

export default function SignIn() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);

  if (localStorage.getItem(CONFIG.accessToken)) {
    history.push(objRoute.home.path);
  }

  const redirectSignUp = () => {
    history.push(objRoute.signUp.path);
  };

  const onFinish = async (values) => {
    setIsDisabled(true);
    try {
      let formData = new FormData();
      formData.append('username', values.username);
      formData.append('password', values.password);

      let result = await AuthService.signIn(formData);
      setIsDisabled(false);

      if (result && result.data) {
        localStorage.setItem(CONFIG.accessToken, result.data.access_token);
        history.push(objRoute.home.path);
      }
    } catch (err) {
      setIsDisabled(false);
      message.error(CONFIG.textWrongAccountOrPassword);
    }
  };

  return (
      <div className={styles.bgLogin + ' h-full min-h-screen'}>
        <div className='container-login md:w-6/12 w-10/12 mx-auto'>
          <div className={styles.title + ' text-white pt-24 text-center'}>
            OCR Tool Website
          </div>
          <div className={styles.description + ' mt-5 text-center'}>
            {'Sign In'}
          </div>
          <Form
              className='mt-12 xl:w-6/12 w-10/12  mx-auto'
              form={form}
              onFinish={onFinish}
          >
            <div className={styles.itemForm + ' mx-auto'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                {'Username'}
              </div>
              <Form.Item
                  name="username"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={200}
                       type='text'/>
              </Form.Item>
            </div>

            <div className={styles.itemForm + ' mx-auto mt-4'}>
              <div className={styles.nameField + ' text-white text-left mb-3'}>
                Password
              </div>
              <Form.Item
                  name="password"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: CONFIG.messageValidForm.required,
                    },
                  ]}
              >
                <Input className='w-full' maxLength={200} type='password'/>
              </Form.Item>

              <div className={'mt-12 xl:mt-16'}>
                <div className={'text-center'}>
                  <button className="btn-submit-form block mx-auto mb-3"
                          disabled={isDisabled}>
                    {'Submit'}
                  </button>
                  <a onClick={redirectSignUp}
                     className={styles.textSignIn + ' text-white mt-3'}>
                    {'Go to sign up'}</a>
                </div>
              </div>

            </div>
            <div className='mt-6 xl:mt-16  text-center'>
              <Footer/>
            </div>
          </Form>
        </div>
      </div>
  );
}
