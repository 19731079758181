import React, {useState} from 'react';
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';

import {HomeService} from '../../services/HomeService';
import {message} from 'antd';
import {CONFIG} from '../../config';

import FileSaver from 'file-saver';

const DisplayTextPdf = (props) => {
  let record = props.record;
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const downloadPdf = async (record) => {
    try {
      setIsDownloadingPdf(true);
      let result = await HomeService.downloadPdf(record.id);
      setIsDownloadingPdf(false);
      if (result && result.data) {
        let blob = new Blob([result.data]);
        FileSaver.saveAs(blob, `${record.name}.pdf`);
      }
    } catch (err) {
      setIsDownloadingPdf(false);
      console.error(err);
      message.error(CONFIG.error);
    }
  };
  return (
      <a className={'flex items-center ' +
      (isDownloadingPdf ? 'cursor-not-allowed' : '')}
         onClick={() => downloadPdf(record)}
      >
        <div className={'mr-2'}>{`${record.name}.pdf`}</div>
        {isDownloadingPdf ? <LoadingOutlined/> : <DownloadOutlined/>}
      </a>
  );
};

export default DisplayTextPdf;