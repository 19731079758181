import React, {useEffect, useRef, useState} from 'react';
import {Col, message, Pagination, Row, Select, Table} from 'antd';
import {ExportOutlined, LoadingOutlined} from '@ant-design/icons';

import MainLayout from '../../layouts/Main';
import ImageConvert from '../../assets/images/image_convert_pdf_to_doc.png';
import CustomUploadDragger from '../../components/Upload';
import styles from './styles.module.scss';
import CustomSelectUpload from '../../components/Upload/CustomSelectUpload';
import {HomeService} from '../../services/HomeService';
import {CONFIG} from '../../config';
import DisplayTextPdf from '../../components/DisplayTextPdf';
import DisplayTextOriginalDocx from '../../components/DisplayTextOriginalDocx';
import DisplayTextReviewedDocx from '../../components/DisplayTextReviewedDocx';

const FileSaver = require('file-saver');

export default function Home() {

  const [fileUploadPdfs, setFileUploadPdfs] = useState(null);
  const [isConvertingPdf, setIsConvertingPdf] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [isUpdateData, setIsUpdateData] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [isExporting, setIsExporting] = useState(false);

  const status = useRef(null);

  const columns = [
    {
      title: 'No',
      key: 'index',
      width: '80px',
      render: (value, item, index) => ((currentPage - 1) * CONFIG.pageSize +
          index) + 1,
    },
    {
      title: 'PDF',
      dataIndex: 'pdf_url',
      width: '350px',
      render: (text, record) => <DisplayTextPdf record={record} text={text}/>,
    },
    {
      title: 'Original DOCX',
      width: '350px',
      dataIndex: 'doc_original_url',
      render: (text, record) => <DisplayTextOriginalDocx record={record}
                                                         text={text}/>,
    },
    {
      title: 'Reviewed DOCX',
      dataIndex: 'doc_reviewed_url',
      width: '350px',
      render: (value, record) => value !== 'None' ?
          <DisplayTextReviewedDocx record={record} value={value}/>
          : <CustomSelectUpload
              configUpload={{
                accept: '.doc,.docx',
              }}
              callbackUpdateData={(isUpdate) => setIsUpdateData(isUpdate)}
              id={record.id}/>,
    },

    {
      title: 'Status',
      width: '150px',
      dataIndex: 'doc_reviewed_url',
      render: (value, record) => value !== 'None' ?
          'Done' : 'In progress',
    },
  ];

  const handleFilterReviewed = async (value) => {
    try {
      status.current = value;
      setCurrentPage(1);
      let result = await loadDocuments({
        start: 0,
        size: CONFIG.pageSize,
        reviewed: value,
      });
    } catch (e) {
      console.error(e);
      message.error(CONFIG.error);
    }

  };

  const updateFile = (files) => {
    setFileUploadPdfs(files);
  };

  const loadDocuments = async (params = '') => {
    try {
      let result = await HomeService.searchDoc({
        size: CONFIG.pageSize,
        ...params,
      });
      if (result.data && result.data.doc_outs) {
        setDataList(result.data.doc_outs || []);
        setTotal(result.data.total || 0);
        setIsUpdateData(false);
      }
    } catch (err) {
      console.error(err);
      message.error(CONFIG.error);
    }

  };

  const convertPdfToDocx = async () => {
    if (fileUploadPdfs && fileUploadPdfs.length) {
      setIsConvertingPdf(true);
      try {
        let formData = new FormData();
        formData.append('file', fileUploadPdfs[0].originFileObj);
        let result = await HomeService.convertPdfToDocx(formData);
        if (result && result.data) {
          setIsConvertingPdf(false);
          setCurrentPage(1);
          status.current = null;
          loadDocuments({
            start: 0,
          });
          setFileUploadPdfs([]);
          message.success(CONFIG.textConvertPdfSuccessfully);
        }
      } catch (err) {
        console.error(err);
        message.error(CONFIG.error);
        setIsConvertingPdf(false);
      }
    } else {
      message.info(CONFIG.messageMustSelectFile);
    }

  };

  const onChangePage = async (page) => {
    try {
      await loadDocuments({
        start: (page - 1) * CONFIG.pageSize,
        reviewed: status.current,
      });
      setCurrentPage(page);
    } catch (err) {
      console.error(err);
      message.error(CONFIG.error);
    }
  };

  const exportCsv = async () => {
    try {
      setIsExporting(true);
      let result = await HomeService.exportCsv({
        reviewed: status.current,
      });
      setIsExporting(false);
      if (result && result.data) {
        let blob = new Blob([result.data], {type: 'text/csv;charset=utf-8'});
        FileSaver.saveAs(blob, `table.csv`);
      }
    } catch (err) {
      setIsExporting(false);
      console.error(err);
      message.error(CONFIG.error);
    }
  };

  useEffect(() => {
    if (isUpdateData) {
      loadDocuments({
        start: (currentPage - 1) * CONFIG.pageSize,
        reviewed: status.current,
      });
    }
  }, [isUpdateData]);

  return (
      <MainLayout>
        <section className='xl:px-24 lg:py-16 py-8 px-5 xl:px-0'>
          <Row>
            <Col md={14} xs={24} className='xl:pr-18 md:pr-6'>
              <h1 className="text-4xl tracking-tight font-extra-bold text-gray-900 sm:text-5xl md:text-5xl">
                <span className="inline">Convert</span>{' '}
                <span
                    className="text-indigo-600 inline">PDF to DOCX</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto md:mt-5 mb-5 md:text-lg lg:mx-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <div className='md:h-26 lg:h-40'>

                <CustomUploadDragger
                    configUpload={{
                      accept: 'application/pdf',
                    }}
                    fileList={fileUploadPdfs}
                    buttonUploadName='attach file'
                    getFileList={file => updateFile(file)}
                />

              </div>
              <div
                  className="mt-5 lg:mt-12 md:mt-8 sm:flex sm:justify-center lg:justify-center">
                <div className="rounded-md shadow">
                  <button
                      disabled={isConvertingPdf}
                      onClick={convertPdfToDocx}
                      className="w-full font-bold flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-color-primary md:py-2 md:text-lg md:px-10"
                  >
                    Convert
                  </button>
                </div>
              </div>
            </Col>
            <Col md={10} xs={24}>
              <div className="w-full relative lg:mt-0 mt-6">
                <img src={ImageConvert} alt={'pdf to word'}
                     className="inset-0 w-full h-full object-cover"/>
              </div>
            </Col>
          </Row>

          <div className={'flex justify-end mt-6'}>
            <div
                className={'inline-block lg:mb-6 mb-3 bg-green-400 ml-auto rounded-lg font-extra-bold ' +
                styles.textExport}>

              <div
                  className={'mx-auto font-bold flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-400 md:py-2 md:text-sm md:px-4'}
                  onClick={() => exportCsv()}
              >
                {isExporting ? <LoadingOutlined/>
                    : <ExportOutlined/>
                }
                <div className={'ml-2'}>Export CSV</div>
              </div>

            </div>
          </div>


          <div className={'flex items-center justify-end'}>
            <label className={'font-bold'}>Status</label>
            <Select className={'ml-4'}
                    value={status.current}
                    style={{width: 120}}
                    onChange={handleFilterReviewed}>
              <Select.Option value={null}>-</Select.Option>
              <Select.Option value={true}>Done</Select.Option>
              <Select.Option value={false}>In progress</Select.Option>
            </Select>

          </div>
          <Table scroll={{x: '375px'}} columns={columns} dataSource={dataList}
                 pagination={false}
                 className={'mt-6'}/>
          <div className={'text-right mt-3'}>
            {!!total && <Pagination showSizeChanger={false}
                                    current={currentPage}
                                    onChange={onChangePage}
                                    total={total} pageSize={CONFIG.pageSize}
            />}
          </div>
        </section>

      </MainLayout>
  );
}
