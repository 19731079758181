export const util = {
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  },

  getTextByKey(key, array) {
    let data = array.filter(item => item.key === key);
    console.log(data);
    return data.length ? data[0].text : '';
  },

  validateByDependency(valueField, messageError) {
    return {
      validator(_, value) {
        if (!value || valueField === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(messageError));
      },
    };
  },
};
