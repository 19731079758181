import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

export const objRoute = {
  signIn: {
    path: '/sign-in',
    exact: true,
    component: SignIn,
    requiredLogin: false,
  },
  signUp: {
    path: '/sign-up',
    exact: true,
    component: SignUp,
    requiredLogin: false,
  },
  home: {
    path: '/',
    exact: true,
    component: Home,
    requiredLogin: true,
  },

};

let routes = [];

for (const key in objRoute) {
  routes.push(objRoute[key]);
}

export default routes;
