import React, {useState} from 'react';
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';

import {HomeService} from '../../services/HomeService';
import {message} from 'antd';
import {CONFIG} from '../../config';

import FileSaver from 'file-saver';

const DisplayTextReviewedDocx = (props) => {
  let record = props.record;
  const [isDownloadingReviewedDoc, setIsDownloadingReviewedDoc] = useState(
      false);

  const downloadReviewedDocx = async (record) => {
    try {
      setIsDownloadingReviewedDoc(true);
      let result = await HomeService.downloadReviewedDocx(record.id);
      setIsDownloadingReviewedDoc(false);
      if (result && result.data) {
        let blob = new Blob([result.data]);
        FileSaver.saveAs(blob, `${record.name}_reviewed.docx`);
      }
    } catch (err) {
      setIsDownloadingReviewedDoc(false);
      console.error(err);
      message.error(CONFIG.error);
    }
  };

  return (
      <a className={'flex items-center ' +
      (isDownloadingReviewedDoc ? 'cursor-not-allowed' : '')}
         onClick={() => downloadReviewedDocx(record)}>

        <div className={'mr-2'}>{`${record.name}_reviewed.docx`}</div>
        {isDownloadingReviewedDoc ? <LoadingOutlined/> : <DownloadOutlined/>}
      </a>
  );
};

export default DisplayTextReviewedDocx;